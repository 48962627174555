<template>
  <el-menu
      default-active="1"
      class="el-menu-demo"
      mode="horizontal">
    <el-menu-item index="1" @click="handelMenu"><i class="el-icon-menu"></i></el-menu-item>
    <el-menu-item index="2" @click="jumpNvaMenu('AlarmSystem')">首页</el-menu-item>
    <div class="icon">
      <div v-show="warnningShow" @click="jumpNvaMenu('AlarmSystem','isShow')"
           style="width:200px;display: inline-block;background-color: #f56c6c;line-height: 32px;text-align: center;margin-right: 60px;border-radius: 5px;">
        <span><i class="el-icon-warning"></i> 警告 发现异常 </span>
      </div>
      <span style="font-size: small;margin-right: 20px">欢迎， {{ userName }}. </span>
      <el-dropdown>
        <el-menu-item index="3">
          <span class="el-dropdown-link">
            <img :src="userImg" alt="" style="height: 40px;width: 40px;border-radius: 50%"></span>
        </el-menu-item>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="jumpNvaMenu('User')">个人中心</el-dropdown-item>
          <el-dropdown-item @click.native="exitLogin">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script>
import axios from "../../api/axios";
import {queryAlarmAll} from "../../api/requests";

export default {
  data() {
    return {
      userName: '',
      warnningShow: false,
      userImg: require('../assets/img/sanlly_logo.png'),
    };
  },
  methods: {
    handelMenu() {
      this.$store.commit('collapseMenu')
    },
    jumpNvaMenu(item, isShow) {
      let param = {
        name: item,
      }
      if (isShow) {
        param['params'] = {isShow: true}
      }

      this.$router.push(param)
    },
    exitLogin() {
      window.localStorage.clear()
      window.location.reload()
    },
    getAuthorization() {
      let Authorization = localStorage.getItem('Authorization');
      this.userName = JSON.parse(Authorization).info[0].fields.username
    },
    sendNewAlarm() {
      let that = this
      if (typeof (EventSource) !== "undefined") {
        let source = new EventSource(axios.baseUrl + "sendnewalarm/");
        source.onmessage = function (event) {
          if (event.data === 'None') {
            return
          }
          that.warnningShow = true
        };
      } else {
        console.log("Sorry, your browser does not support server-sent events...");
      }
    },
  },
  mounted() {
    this.getAuthorization()
    let user = localStorage.getItem('Authorization')
    queryAlarmAll({'info': user}).then(res => {
      if (res.data.noHandled_len > 0) {
        this.warnningShow = true
      }
    })
  }
}
</script>


<style scoped>
.icon {
  float: right;
}
</style>