<template>
  <div class="icp-footer">
    <span>©2022 杉谷科技有限公司 All Rights Reserved.</span>
    <span><a href="https://beian.miit.gov.cn/" target="_blank" class="text-gray-800 text-hover-primary">粤ICP备2021057892号-3</a></span>
  </div>
</template>

<script>
export default {
  name: "CommonFooter"
}
</script>

<style scoped>

</style>